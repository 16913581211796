import { Component } from 'react'
import { Redirect } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import './Absence.css'
import 'react-datepicker/dist/react-datepicker.css'

// MUST MATCH ORDER OF SQL MODEL
const absenceType = [
  "Holiday",
  "Feriefridag",
  "Sickday",
  "Child Sickday",
  "Time-Off",
];


export default class Absence extends Component {
  constructor() {
    super()
    this.state = {
      note: '',
      type: absenceType[1],
      start: (new Date()).toISOString(),
      end: (new Date()).toISOString(),
      absence: JSON.parse(localStorage.getItem('tlog.absence')) || [],
    }
  }

  subtractMonths(numOfMonths, date = new Date()) {
    date.setMonth(date.getMonth() - numOfMonths);
    return date
  }

  componentDidMount() {
    if (localStorage.getItem('tlog.base-url') && localStorage.getItem('tlog.token')) {
      this.loadAbsence()
    }
  }

  async loadAbsence() {
    const base = localStorage.getItem('tlog.base-url')
    const token = localStorage.getItem('tlog.token')
    try {
      const res = await fetch(`${base}/absence`, {
        headers: { token },
      })

      const data = await res.json()
      this.setState({ absence: data || [] })
      localStorage.setItem('tlog.absence', JSON.stringify(data || []))
    } catch(err) {}
  }

  async registerAbsence() {
    const base = localStorage.getItem('tlog.base-url')
    const token = localStorage.getItem('tlog.token')

    let end = moment(this.state.end, "YYYY-MM-DD HH:mm:ss").utc().set({hour: 0, minute: 0, second: 0}).toISOString()
    let start = moment(this.state.start, "YYYY-MM-DD HH:mm:ss").utc().set({hour: 0, minute: 0, second: 0}).toISOString()
    if(this.state.end === null) {
      this.setState({end: this.state.start})
      end = start
    }
    await fetch(base+'/absence', {
      method: 'POST',
      headers: { token },
      body: JSON.stringify({
        type: absenceType.indexOf(this.state.type),
        note: this.state.note,
        start: start,
        end: end,
      })
    }).then((response) => {
      if (response.status === 400) {
        return response.text()
      }
      return ""
    }).then((error) => {
      if (error !== "") {
        alert(error)
      }
    })

    this.loadAbsence()
  }

  async deleteAbsence(event) {
    const base = localStorage.getItem('tlog.base-url')
    const token = localStorage.getItem('tlog.token')
    await fetch(base+'/absence/delete', {
      method: 'POST',
      headers: { token },
      body: JSON.stringify({
        id: parseInt(event.target.value),
      })
    })
    this.loadAbsence()
  }

  setDates (event) {
    const [start, end] = event
    if (end != null) {
      this.setState({end: end.toISOString()})
    } else {
      this.setState({start: start.toISOString()})
      this.setState({end: end})
    }
  }

  setNote(event) {
    this.setState({ note: event.target.value })
  }

  setType(event) {
    this.setState({ type: event.target.value })
  }

  render() {

    if (!localStorage.getItem('tlog.base-url') || !localStorage.getItem('tlog.token')) {
      return <Redirect to='/setup' />
    }
    return (
      <main className='absence-view'>
        <section className='date-picker-container'>
          <h3>Absence</h3>
          <section className='date-picker'>
              <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={Date.parse(this.state.start)}
                startDate={Date.parse(this.state.start)}
                endDate={Date.parse(this.state.end)}
                onChange={this.setDates.bind(this)}
                inline
                selectsRange
                showWeekNumbers
                calendarStartDay={1}
              />
          </section>
        </section>
        <section className='type-picker-container'>
          <select name="type" className='type-picker' defaultValue={this.state.type} onChange={this.setType.bind(this)}>
            {absenceType.map(type => (
              <option value={type} key={type}>{type}</option>
            ))}
          </select>
        </section>
        <section className='note-container'>
          <h4>Note:</h4>
          <input className='note' placeholder='Some descriptive note...' value={this.state.note} onChange={this.setNote.bind(this)}></input>
        </section>
        <section className='register-container'>
          <button className='register' onClick={this.registerAbsence.bind(this)}>Register</button>
        </section>
        <section>
        {this.state.absence.sort((a, b) => moment(b.start).diff(moment(a.start))).map(absence => (
              <div key={absence.id} id={absence.id} className='task'>
                <h1>A{absence.id} - {absenceType[absence.type]} {absence.note !== "" && ("- " + absence.note)}</h1>
                {(moment(absence.start).format('DD') === moment(absence.end).format('DD') && (
                  <small>{moment(absence.start).format('YYYY-MM-DD')}</small>
                )) || (
                  <small>{moment(absence.start).format('YYYY-MM-DD')} - {moment(absence.end).format('YYYY-MM-DD')}</small>
                )}
                <button value={absence.id} onClick={this.deleteAbsence.bind(this)}>Delete</button>
              </div>
          ))}
        </section>
      </main>
    )
  }
}
