import { Component } from 'react'
import { Redirect } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import './Status.css'
import 'react-datepicker/dist/react-datepicker.css'

export default class Status extends Component {
  constructor() {
    super()
    this.state = {
      start: moment(this.subtractMonths(1).toISOString()).format('YYYY-MM-DD HH:mm:ss'),
      end: moment((new Date()).toISOString()).format('YYYY-MM-DD HH:mm:ss'),
      tasks: JSON.parse(localStorage.getItem('tlog.tasks')) || [],
      logs: JSON.parse(localStorage.getItem('tlog.logs')) || [],
      latest: JSON.parse(localStorage.getItem('tlog.latest')) || {},
    }
  }

  subtractMonths(numOfMonths, date = new Date()) {
    date.setMonth(date.getMonth() - numOfMonths);
    return date
  }

  componentDidMount() {
  }

  async getStatus() {
    const base = localStorage.getItem('tlog.base-url')
    const token = localStorage.getItem('tlog.token')
    try{
      const res = await fetch(`${base}/status?from=${moment(this.state.start, "YYYY-MM-DD HH:mm:ss").utc().set({hour: 0, minute: 0, second: 0}).toISOString()}&to=${moment(this.state.end, "YYYY-MM-DD HH:mm:ss").utc().set({hour:23, minute:59, second:59, millisecond:999}).toISOString()}`, {
        headers: { token },
      })
      console.log(res.url)
      if (!res.ok) {
        return
      }
      const data = await res.json()

      const target = document.querySelector('#target')
      const logged = document.querySelector('#logged')
      const difference = document.querySelector('#difference')
      const feriefridag = document.querySelector('#feriefridag')
      const vacation = document.querySelector('#vacation')

      target.innerHTML = data.target.toFixed(2)
      logged.innerHTML = data.logged.toFixed(2)
      difference.innerHTML = data.difference.toFixed(2)
      feriefridag.innerHTML = data.feriefridagdays.toFixed(1)
      vacation.innerHTML = data.vacationdays.toFixed(1)

    }catch(err){console.log(err)}
  }

  render() {

    if (!localStorage.getItem('tlog.base-url') || !localStorage.getItem('tlog.token')) {
      return <Redirect to='/setup' />
    }
    // State updating should be improved, as adding onMonthChange will case some
    // undefined behaviour.

    // Also it should be possible to add a list with week, target, logged and
    // difference. This will require changes to the status endpoint.
    return (
      <main className='status-view'>
        <section className='date-picker-container'>
          <h3>Status</h3>
          <section className='date-pickers'>
            <div>
              <h4>From:</h4>
              <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={Date.parse(this.state.start)}
                onChange={(date) => this.setState({start: date.toISOString()})}
                inline
                showWeekNumbers
                calendarStartDay={1}
                selectsStart
                startDate={Date.parse(this.state.start)}
                endDate={Date.parse(this.state.end)}
                minDate={Date.parse("2021-06-01")}
                maxDate={this.subtractMonths(0)}
              />
            </div>
            <div>
              <h4>To:</h4>
              <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={Date.parse(this.state.end)}
                onChange={(date) => this.setState({end: date.toISOString()})}
                inline
                showWeekNumbers
                calendarStartDay={1}
                todayButton="Today"
                selectsEnd
                startDate={Date.parse(this.state.start)}
                endDate={Date.parse(this.state.end)}
                minDate={Date.parse(this.state.start)}
                maxDate={this.subtractMonths(0)}
              />
            </div>
          </section>
        </section>
        <section className='show-container'>
          <button className='show' onClick={this.getStatus.bind(this)}>Show</button>
        </section>
        <section className='result-container'>
          <table className='result'>
            <thead className='header'>
              <tr>
                <td>Target</td>
                <td>Logged</td>
                <td>Difference</td>
                <td>Spent Feriefridage</td>
                <td>Spent Vacation Days</td>
              </tr>
            </thead>
            <tbody className='data'>
              <tr>
                <td id='target'></td>
                <td id='logged'></td>
                <td id='difference'></td>
                <td id='feriefridag'></td>
                <td id='vacation'></td>
              </tr>
            </tbody>
          </table>
        </section>
      </main>
    )
  }
}
