import React from 'react';
import { Link } from 'react-router-dom';

import './NavBar.css';

const NavBar = () => {
  if (!localStorage.getItem('tlog.base-url') || !localStorage.getItem('tlog.token')) {
    return null
  }
  return (
    <section className='navbar-view'>
      <div className='navbar'>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/view/status">Status</Link></li>
          <li><Link to="/view/absence">Absence</Link></li>
          <li><Link to="/view/csv">CSV-Report</Link></li>
        </ul>
       </div>
    </section>
  );
};

export default NavBar;
