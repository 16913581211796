import { Component } from 'react'
import { Redirect } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import './Csv.css'
import 'react-datepicker/dist/react-datepicker.css'



var headers = {
  task_id: 'Task ID',
  log_id: 'Log ID',
  type:   'Entry Type',
  account_name: 'Account',
  project: 'Project',
  activity: 'Activity',
  task: 'Description',
  start: 'Start',
  end: 'End',
  used: 'Used'
};

export default class Csv extends Component {
  constructor() {
    super()
    this.state = {
      start: moment(this.subtractMonths(1).toISOString()).format('YYYY-MM-DD HH:mm:ss'),
      end: moment((new Date()).toISOString()).format('YYYY-MM-DD HH:mm:ss'),
      report: JSON.parse(localStorage.getItem('tlog.report')) || [],
      reportMode: "single",
    }
  }

  subtractMonths(numOfMonths, date = new Date()) {
    date.setMonth(date.getMonth() - numOfMonths);
    return date
  }

  componentDidMount() {
    if (localStorage.getItem('tlog.base-url') && localStorage.getItem('tlog.token')) {
      this.loadReport()
    }
  }

  async loadReport() {
    const base = localStorage.getItem('tlog.base-url')
    const token = localStorage.getItem('tlog.token')
    try{
      const res = await fetch(`${base}/csv?mode=${this.state.reportMode}&from=${moment(this.state.start, "YYYY-MM-DD HH:mm:ss").utc().set({hour: 0, minute: 0, second: 0}).toISOString()}&to=${moment(this.state.end, "YYYY-MM-DD HH:mm:ss").utc().set({hour:23, minute:59, second:59, millisecond:999}).toISOString()}`, {
        headers: { token },
      })
      console.log(res.url)
      if (!res.ok) {
        return
      }
      const data = await res.json()

      this.setState({ report: data || [] })
      localStorage.setItem('tlog.report', JSON.stringify(data || []))
    }catch(err){console.log(err)}
  }

  convertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line != '') line += ','

            line += "\"" + array[i][index] + "\"";
        }

        str += line + '\r\n';
    }

    return str;
  }

  async downloadReport() {
    await this.loadReport()

    var items = this.state.report

    if (headers) {
      items.unshift(headers);
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = this.convertToCSV(jsonObject);

    var fileName = moment(this.state.start).format("MM-DD-YY") + "_" +moment(this.state.end).format("MM-DD-YY")

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, fileName);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
  }

  setMode(event) {
    var mode = "single"
    if (event.target.checked) {
      mode = "all"
    }
    this.setState({reportMode: mode}, () => {
      this.loadReport()
    })

  }

  render() {

    if (!localStorage.getItem('tlog.base-url') || !localStorage.getItem('tlog.token')) {
      return <Redirect to='/setup' />
    }
    const report = this.state.report.slice();

    return (
      <main className='csv-view'>
        <section className='date-picker-container'>
          <h3>CSV-Report</h3>
          <section className='date-pickers'>
            <div>
              <h4>From:</h4>
              <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={Date.parse(this.state.start)}
                onChange={(date) => this.setState({start: date.toISOString()})}
                showWeekNumbers
                startDate={Date.parse(this.state.start)}
                endDate={Date.parse(this.state.end)}
                selectsStart
                maxDate={this.subtractMonths(0)}
                showMonthYearDropdown
                calendarStartDay={1}
              />
            </div>
            <div>
              <h4>To:</h4>
              <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={Date.parse(this.state.end)}
                onChange={(date) => this.setState({end: date.toISOString()})}
                showWeekNumbers
                startDate={Date.parse(this.state.start)}
                endDate={Date.parse(this.state.end)}
                selectsEnd
                maxDate={this.subtractMonths(0)}
                showMonthYearDropdown
                calendarStartDay={1}
                todayButton="Today"
              />
            </div>
          </section>
        </section>
        <section className='options-container'>
          <div className='mode-select'>
            <label>
              <input type='checkbox' id='mode' name='mode' onChange={this.setMode.bind(this)} />
              Show All Accounts
            </label>
          </div>
        </section>
        <section className='show-container'>
          <button className='show' onClick={this.loadReport.bind(this)}>Show</button>
          <button className='show' onClick={this.downloadReport.bind(this)}>Download</button>
        </section>
        <section className='result-container'>
          <table className='result'>
            <thead className='header'>
              <tr>
                {
                  (this.state.reportMode === "all") && <td>User</td>
                }
                <td>Type</td>
                <td>LogID</td>
                <td>Description</td>
                <td>Start</td>
                <td>End</td>
                <td>Used</td>
              </tr>
            </thead>
            <tbody className='data'>
              {
                report.map(entry => (
                  <tr key={entry.log_id}>
                    {this.state.reportMode == "all" && <td>{entry.account_name}</td>}
                    <td>{entry.type}</td>
                    <td>{entry.log_id}</td>
                    <td className='stretch'>{entry.task}</td>
                    <td>{moment(entry.start).format("DD-MM-YY - HH:mm:ss")}</td>
                    <td>{moment(entry.end).format("DD-MM-YY - HH:mm:ss")}</td>
                    <td>{entry.used.toFixed(2)}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </section>
      </main>
    )
  }
}
