import { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import './Tasks.css'

function A({ href, children }) {
  let text = href

  if (text.includes('https://github.com/')) {
      text = text.replace('https://github.com/', '')
        .replace('/issues/', '#')
        .replace('/pull/', '#')
  }

  return text
}

function used(used, latest) {
  let running = 0

  if (latest && !latest.end) {
    const t = new Date(Date.now() - Date.parse(latest.start))
    running = t.getUTCHours() + (t.getUTCMinutes()/60) + (t.getUTCSeconds()/3600)
  }

  const date = new Date((used+running)*3.6e+6)

  return pad(Math.floor(used+running))+":"+pad(date.getUTCMinutes())+":"+pad(date.getUTCSeconds())
}

function pad(n, zeros) {
  let s = String(n)
  while (s.length < (zeros || 2)) {s = "0" + s}
  return s
}

function filterTasks(filter) {
  return task => (
    task.title.toLowerCase().includes(filter.toLowerCase()) ||
    task.reference.toLowerCase().includes(filter.toLowerCase()) ||
    task.reference.replace('https://github.com/', '')
      .replace('/issues/', '#')
      .replace('/pull/', '#').toLowerCase()
      .includes(filter.toLowerCase())
  )
}

export default class Tasks extends Component {
  constructor() {
    super()
    this.interval = undefined
    this.state = {
      search: localStorage.getItem('tlog.tasks.filter') || '',
      checked: JSON.parse(localStorage.getItem('tlog.tasks.checked')) || [],
      tasks: JSON.parse(localStorage.getItem('tlog.tasks')) || [],
      latest: JSON.parse(localStorage.getItem('tlog.latest')) || {},
      clock: 0,
    }
  }

  componentDidMount() {
    if (localStorage.getItem('tlog.base-url') && localStorage.getItem('tlog.token')) {
      this.loadTasks()
      this.loadLatest()
    }
    this.interval = setInterval(() => this.setState({ clock: this.clock++ }), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  async loadTasks() {
    const base = localStorage.getItem('tlog.base-url')
    const token = localStorage.getItem('tlog.token')
    try {
      const res = await fetch(base+'/tasks', {
        headers: { token }
      })

      const data = await res.json()
      this.setState({ tasks: data || [] })
      localStorage.setItem('tlog.tasks', JSON.stringify(data || []))
    } catch(err) {}
  }

  async loadLatest() {
    const base = localStorage.getItem('tlog.base-url')
    const token = localStorage.getItem('tlog.token')
    try {
      const res = await fetch(base+'/logs/latest', {
        headers: { token }
      })
      const data = await res.json()
      this.setState({ latest: data || {} })
      localStorage.setItem('tlog.latest', JSON.stringify(data || {}))
    } catch(err) {}
  }

  search(event) {
    this.setState({ search: event.target.value })
    localStorage.setItem('tlog.tasks.filter', event.target.value)
  }

  async createTask() {
    const base = localStorage.getItem('tlog.base-url')
    const token = localStorage.getItem('tlog.token')
    await fetch(base+'/tasks', {
      method: 'POST',
      headers: { token },
      body: JSON.stringify({
        title: this.state.search,
      })
    })
    this.loadTasks()
  }

  async startTimer(task) {
    if (!this.state.latest.end && this.state.latest.task_id === task.id) {
      return
    }

    const base = localStorage.getItem('tlog.base-url')
    const token = localStorage.getItem('tlog.token')
    await fetch(base+'/logs', {
      method: 'POST',
      headers: { token },
      body: JSON.stringify({
        task_id: task.id,
        description: task.title,
      })
    })

    this.loadLatest()
    this.loadTasks()
  }

  async stopTimer() {
    const base = localStorage.getItem('tlog.base-url')
    const token = localStorage.getItem('tlog.token')
    await fetch(base+'/logs/stop', {
      method: 'PUT',
      headers: { token },
    })

    this.loadLatest()
    this.loadTasks()
  }

  checkTask(task) {
    let state = []
    if (this.state.checked.includes(task.id)) {
      state = { checked: this.state.checked.filter(id => task.id !== id) }
    } else {
      state = { checked: [...this.state.checked, task.id] }
    }
    this.setState(state, () => {
      localStorage.setItem('tlog.tasks.checked', JSON.stringify(this.state.checked))
    })
  }

  render() {
    if (!localStorage.getItem('tlog.base-url') || !localStorage.getItem('tlog.token')) {
      return <Redirect to='/setup' />
    }

    return (
      <main className='task-list-view'>
        <div className='search-container'>
          <section>
            <input onChange={this.search.bind(this)} placeholder='Search...' value={this.state.search} />
            {this.state.search !== "" && this.state.tasks.filter(task => task.title === this.state.search).length !== 1 && (
              <button onClick={this.createTask.bind(this)}>Create</button>
            )}
          </section>
        </div>
        <section>
          {this.state.tasks.filter(filterTasks(this.state.search)).filter(task => !this.state.checked.includes(task.id) || this.state.search).map(task => (
            <div key={task.id} className={['task', !this.state.latest.end && this.state.latest.task_id === task.id ? 'active' : '', this.state.checked.includes(task.id) ? 'checked' : ''].join(' ')}>
              <Link to={`/${task.id}`}>
                <h1>{task.title}</h1>
                <small>
                  <A href={task.reference || localStorage.getItem('tlog.base-url')+'/task/'+task.id} />
                </small>
              </Link>
              <div onClick={this.startTimer.bind(this, task)} className='time'>{used(task.used, this.state.latest.task_id === task.id ? this.state.latest : undefined)}</div>
              <input onChange={this.checkTask.bind(this, task)} checked={this.state.checked.includes(task.id)} type="checkbox" />
            </div>
          ))}
          {this.state.latest.start && !this.state.latest.end && (
            <div className='stop-container'>
              <section>
                <button onClick={this.stopTimer.bind(this)} className='stop'>Stop</button>
              </section>
            </div>
          )}
        </section>
      </main>
    )
  }
}
