import { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import moment from 'moment'
import './Logs.css'

function A({ href, children }) {
  let text = href

  if (text.includes('https://github.com/')) {
      text = text.replace('https://github.com/', '')
        .replace('/issues/', '#')
        .replace('/pull/', '#')
  }

  return <a href={href} target="_blank">{text}</a>
}

function used(used, latest) {
  let running = 0

  if (latest && !latest.end) {
    const t = new Date(Date.now() - Date.parse(latest.start))
    running = t.getUTCHours() + (t.getUTCMinutes()/60) + (t.getUTCSeconds()/3600)
  }

  const date = new Date((used+running)*3.6e+6)

  return pad(Math.floor(used+running))+":"+pad(date.getUTCMinutes())+":"+pad(date.getUTCSeconds())
}

function pad(n, zeros) {
  let s = String(n)
  while (s.length < (zeros || 2)) {s = "0" + s}
  return s
}

export default class Logs extends Component {
  constructor() {
    super()
    this.interval = undefined
    this.state = {
      tasks: JSON.parse(localStorage.getItem('tlog.tasks')) || [],
      logs: JSON.parse(localStorage.getItem('tlog.logs')) || [],
      latest: JSON.parse(localStorage.getItem('tlog.latest')) || {},
      clock: 0,
    }
  }

  componentDidMount() {
    if (localStorage.getItem('tlog.base-url') && localStorage.getItem('tlog.token')) {
      this.loadTasks()
      this.loadLatest()
      this.loadLogs()
    }
    this.interval = setInterval(() => this.setState({ clock: this.clock++ }), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  async loadTasks() {
    const base = localStorage.getItem('tlog.base-url')
    const token = localStorage.getItem('tlog.token')
    try {
      const res = await fetch(base+'/tasks', {
        headers: { token }
      })

      const data = await res.json()
      this.setState({ tasks: data || [] })
      localStorage.setItem('tlog.tasks', JSON.stringify(data || []))
    } catch(err) {}
  }

  async loadLogs() {
    const base = localStorage.getItem('tlog.base-url')
    const token = localStorage.getItem('tlog.token')
    try {
      const res = await fetch(`${base}/logs?from=2000-01-01T00:00:00Z&to=${new Date().toISOString()}`, {
        headers: { token },
      })

      const data = await res.json()
      this.setState({ logs: data || [] })
      localStorage.setItem('tlog.logs', JSON.stringify(data || []))
    } catch(err) {}
  }

  async loadLatest() {
    const base = localStorage.getItem('tlog.base-url')
    const token = localStorage.getItem('tlog.token')
    try {
      const res = await fetch(base+'/logs/latest', {
        headers: { token }
      })
      const data = await res.json()
      this.setState({ latest: data || {} })
      localStorage.setItem('tlog.latest', JSON.stringify(data || {}))
    } catch(err) {}
  }

  async startTimer(task) {
    if (!this.state.latest.end && this.state.latest.task_id === task.id) {
      return
    }

    const base = localStorage.getItem('tlog.base-url')
    const token = localStorage.getItem('tlog.token')
    await fetch(base+'/logs', {
      method: 'POST',
      headers: { token },
      body: JSON.stringify({
        task_id: task.id,
        description: task.title,
      })
    })

    this.loadLatest()
    this.loadLogs()
    this.loadTasks()
  }

  async stopTimer() {
    const base = localStorage.getItem('tlog.base-url')
    const token = localStorage.getItem('tlog.token')
    await fetch(base+'/logs/stop', {
      method: 'PUT',
      headers: { token },
    })

    this.loadLogs()
    this.loadLatest()
    this.loadTasks()
  }

  render() {
    const {id} = this.props.match.params
    const task = this.state.tasks.find(task => task.id === Number(id))

    if (!localStorage.getItem('tlog.base-url') || !localStorage.getItem('tlog.token')) {
      return <Redirect to='/setup' />
    }

    if (!task) {
      return <h1>Not found...</h1>
    }

    return (
      <main className='task-logs-view'>
        <div className='task-container'>
          <section>
            <div className={['task', !this.state.latest.end && this.state.latest.task_id === task.id ? 'active' : ''].join(' ')}>
              <h1>{task.title}</h1>
              <small>
                <A href={task.reference || localStorage.getItem('tlog.base-url')+'/'+task.id} />
              </small>
              <div onClick={this.startTimer.bind(this, task)} className='time'>{used(task.used, this.state.latest.task_id === task.id ? this.state.latest : undefined)}</div>
            </div>
          </section>
        </div>
        <section>
          {this.state.logs.filter(log => log.task_id === task.id).sort((a, b) => a.id < b.id).map(log => (
            <Link to={`/${task.id}/${log.id}`}>
              <div key={log.id} id={log.id} className='task'>
                <h1>T{task.id}L{log.id} - {log.description}</h1>
                {moment(log.start).format('DD') === moment(log.end).format('DD') && (
                  <small>{moment(log.start).format('YYYY-MM-DD HH:mm:ss')} - {moment(log.end).format('HH:mm:ss')}</small>
                ) || (
                  <small>{moment(log.start).format('YYYY-MM-DD HH:mm:ss')} - {moment(log.end).format('YYYY-MM-DD HH:mm:ss')}</small>
                )}
                <div className='time'>{moment.duration(moment(log.end).diff(log.start)).asHours().toFixed(2)}</div>
              </div>
            </Link>
          ))}
        </section>
        {!this.state.latest.end &&  this.state.latest.task_id === task.id && (
          <div className='stop-container'>
            <section>
              <button onClick={this.stopTimer.bind(this)} className='stop'>Stop</button>
            </section>
          </div>
        )}
      </main>
    )
  }
}
