import { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import moment from 'moment'
import './ViewLog.css'

function A({ href, children }) {
  let text = href

  if (text.includes('https://github.com/')) {
      text = text.replace('https://github.com/', '')
        .replace('/issues/', '#')
        .replace('/pull/', '#')
  }

  return <a href={href} target="_blank">{text}</a>
}


export default class ViewLog extends Component {
  constructor() {
    super()
    this.state = {
      start: "",
      end: "",
      tasks: JSON.parse(localStorage.getItem('tlog.tasks')) || [],
      logs: JSON.parse(localStorage.getItem('tlog.logs')) || [],
      latest: JSON.parse(localStorage.getItem('tlog.latest')) || {},
    }
  }

  componentDidMount() {
    this.loadTasks()
    this.loadLatest()
    this.loadLogs()
  }

  async loadTasks() {
    const base = localStorage.getItem('tlog.base-url')
    const token = localStorage.getItem('tlog.token')
    try {
      const res = await fetch(base+'/tasks', {
        headers: { token }
      })

      const data = await res.json()
      this.setState({ tasks: data || [] })
      localStorage.setItem('tlog.tasks', JSON.stringify(data || []))
    } catch(err) {}
  }

  async loadLogs() {
    const base = localStorage.getItem('tlog.base-url')
    const token = localStorage.getItem('tlog.token')
    try {
      const res = await fetch(`${base}/logs?from=2000-01-01T00:00:00Z&to=${new Date().toISOString()}`, {
        headers: { token },
      })

      const data = await res.json()
      this.setState({ logs: data || [] })
      localStorage.setItem('tlog.logs', JSON.stringify(data || []))
    } catch(err) {}
  }

  async loadLatest() {
    const base = localStorage.getItem('tlog.base-url')
    const token = localStorage.getItem('tlog.token')
    try {
      const res = await fetch(base+'/logs/latest', {
        headers: { token }
      })
      const data = await res.json()
      this.setState({ latest: data || {} })
      localStorage.setItem('tlog.latest', JSON.stringify(data || {}))
    } catch(err) {}
  }

  async updateLog() {
    const base = localStorage.getItem('tlog.base-url')
    const token = localStorage.getItem('tlog.token')
    const {taskID, logID} = this.props.match.params
    try {
      const res = await fetch(base+'/logs', {
        method: 'PUT',
        headers: { token },
        body: JSON.stringify([{
          id: Number(logID),
          task_id: Number(taskID),
          start: moment(this.state.start, "YYYY-MM-DD HH:mm:ss").toISOString(),
          end: moment(this.state.end, "YYYY-MM-DD HH:mm:ss").toISOString(),
        }])
      })
      this.loadLogs()
    } catch(err) {}
  }



  setDate(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const {taskID, logID} = this.props.match.params
    const task = this.state.tasks.find(task => task.id === Number(taskID))
    const log = this.state.logs.find(log => log.id === Number(logID))
    if (this.state.start == "") {
      this.setState({
        start: moment(log.start).format('YYYY-MM-DD HH:mm:ss'),
        end: moment(log.end).format('YYYY-MM-DD HH:mm:ss'),
      })
    }

    if (!localStorage.getItem('tlog.base-url') || !localStorage.getItem('tlog.token')) {
      return <Redirect to='/setup' />
    }

    if (!task || !log) {
      return <h1>Not found...</h1>
    }

    return (
      <main className='task-log-view'>
        <section>
          <h3>T{task.id}L{log.id} - {task.title}</h3>
          <h4>Hours: {moment.duration(moment(log.end).diff(log.start)).asHours().toFixed(2)}</h4>
          <p>{log.description}</p>
          <p><A href={task.reference || localStorage.getItem('tlog.base-url')+'/'+task.id} /></p>
          <input name="start" onChange={this.setDate.bind(this)} type='text' value={this.state.start} />
          <input name="end" onChange={this.setDate.bind(this)} type='text' value={this.state.end} />
        </section>
        { (moment(log.start).format('YYYY-MM-DD HH:mm:ss') != this.state.start || moment(log.end).format('YYYY-MM-DD HH:mm:ss') != this.state.end)  && (
          <section className='save-container'>
            <button onClick={this.updateLog.bind(this)} className='save'>Save</button>
          </section>
        )}
      </main>
    )
  }
}
