import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom"
import Setup from './Setup'
import Tasks from './Tasks'
import Logs from './Logs'
import ViewLog from './ViewLog'
import Status from './Status'
import Absence from './Absence'
import Csv from './Csv'
import NavBar from './NavBar'
import '@quartercastle/style/base.css'
import './index.css'



(function setupDarkMode() {
  const theme = localStorage.getItem('theme')

  if (theme) {
    return document.body.classList.add(theme)
  }

  const mql = window.matchMedia('(prefers-color-scheme: dark)')

  if (mql.addEventListener) {
    mql.addEventListener("change", (e) => {
      if (e.matches) {
        document.body.classList.add('dark')
      } else {
        document.body.classList.remove('dark')
      }
    })
  }

  if (mql.matches) {
    document.body.classList.add('dark')
  }
})()

ReactDOM.render(
  <React.StrictMode>
    <Router>
    <NavBar/>
        <Switch>
          <Route path='/setup' component={Setup} />
          <Route path='/view/status' component={Status} />
          <Route path='/view/absence' component={Absence} />
          <Route path='/view/csv' component={Csv} />
          <Route path='/:taskID/:logID' component={ViewLog} />
          <Route path='/:id' component={Logs} />
          <Route path='/' component={Tasks} />
        </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)
