import { Component } from 'react'
import { Redirect } from 'react-router-dom'
import './Setup.css'

export default class Setup extends Component {
  constructor() {
    super()
    this.state = {
      value: "",
      gotHost: localStorage.getItem('tlog.base-utl') || false,
      gotToken: localStorage.getItem('tlog.token') || false,
      isRequested: localStorage.getItem('tlog.token.requested') || false
    }
  }

  setHost(event) {
    event.preventDefault()
    localStorage.setItem('tlog.base-url', this.state.value)
    this.setState({ gotHost: true, value: '' })
  }

  async requestToken(event) {
    event.preventDefault()
    const baseUrl = localStorage.getItem('tlog.base-url')
    try {
      await fetch(baseUrl+'/token', {
        method: 'post',
        body: this.state.value,
      })
      localStorage.setItem('tlog.token.requested', true)
      this.setState({ isRequested: true, value: '' })
    } catch(err) {}
  }

  async setup(event) {
    event.preventDefault()
    localStorage.removeItem('tlog.token.requested')
    localStorage.setItem('tlog.token', event.target.querySelector('input[name=token]').value)
    this.setState({ gotToken: true })
  }

  onChange(event) {
    this.setState({ value: event.target.value })
  }

  render () {
    if (this.state.gotToken) {
      return <Redirect to={"/"} />
    }

    if (!this.state.gotHost) {
      return (
        <main>
          <section className="setup-view">
            <div className="setup-box">
              <h1>tlog host</h1>
              <form onSubmit={this.setHost.bind(this)}>
                <input
                  name='host'
                  placeholder="https://example.com"
                  type="text"
                  value={this.state.value}
                  onChange={this.onChange.bind(this)}
                />
                <button type='submit'>Set</button>
              </form>
            </div>
          </section>
        </main>
      )
    }

    if (!this.state.isRequested) {
      return (
        <main>
          <section className="setup-view">
            <div className="setup-box">
              <h1>Request token</h1>
              <form onSubmit={this.requestToken.bind(this)}>
                <input
                  name='email'
                  placeholder="Email"
                  type="email"
                  value={this.state.value}
                  onChange={this.onChange.bind(this)}
                />
                <button type='submit'>Send</button>
              </form>
            </div>
          </section>
        </main>
      )
    }

    return (
      <main>
        <section className="setup-view">
          <div className="setup-box">
            <h1>Login with token</h1>
            <form onSubmit={this.setup.bind(this)}>
              <input
                name='token'
                placeholder="Token from email"
                type="text"
                value={this.state.value}
                onChange={this.onChange.bind(this)}
              />
              <button>Login</button>
            </form>
          </div>
        </section>
      </main>
    )
  }
}
